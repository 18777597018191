<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-md-12">
                            <!--begin::Card-->
                            <AdminWidget3 :coachlistid="coachid"></AdminWidget3>
                            <!--end::Card-->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <!--begin::Card-->
                            <AdminWidget1 :coachlistid="coachid"></AdminWidget1>
                            <AdminWidget4 :coachlistid="coachid"></AdminWidget4>
                            <AdminWidget5 :coachlistid="coachid"></AdminWidget5>
                            <!--end::Card-->
                        </div>
                        <div class="col-lg-6">
                            <!-- begin::Card -->
                            <div class="card card-custom gutter-b">
                                <!--begin::Header-->
                                <div class="card-header border-0">
                                    <h3 class="card-title font-weight-bolder text-dark">Messages</h3>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0">
                                    <b-container fluid>
                                        <!-- User Interface controls -->
                                        <b-row>
                                            <b-col lg="12" class="my-1">
                                                <b-form-group label="Filter" label-for="filter-input" label-cols-sm="2"
                                                    label-align-sm="right" label-size="sm" class="mb-0">
                                                    <b-input-group size="sm">
                                                        <b-form-input id="filter-input" v-model="filter" type="search"
                                                            placeholder="Type to Search"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-button :disabled="!filter"
                                                                @click="filter = ''">Clear</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-table id="messages-table" :items="coachMessageItems" :fields="messagefields"
                                            responsive="sm" :head-variant="headVariant" :per-page="perPage"
                                            :current-page="currentMessagesPage" :sort-by.sync="sortBy"
                                            :sort-desc.sync="sortDesc" :sort-compare="mySortCompare" show-empty
                                            thead-class="hidden_header" :filter="filter" empty-text="You have no new messages.">
                                            <template #cell(image)="data">
                                                <div class="symbol symbol-50 mw-50 pointer"
                                                    v-on:click="loadMessage(data.item)">
                                                    <img alt="Pic" :src="data.item.user.userProfileImage" />
                                                </div>
                                            </template>
                                            <template #cell(title)="data">
                                                <div class="d-flex flex-column" v-on:click="loadMessage(data.item)">
                                                    <a href="javascript:void()"
                                                        class="text-dark text-hover-primary font-weight-bold font-size-lg">{{
                                                            data.item.user.userName }}</a>
                                                    <span class="text-dark font-weight-bold font-size-sm pointer">{{
                                                        data.item.text.substring(0,
                                                            100) }}
                                                        <span class="text-dark font-weight-bold font-size-sm"
                                                            v-if="data.item.text.length > 100">...</span>
                                                    </span>
                                                </div>
                                            </template>
                                            <template #cell(datetime)="data">
                                                <div class="d-flex flex-column mnw-150 text-right"
                                                    v-if="data.item.coachRead">
                                                    <span class="text-dark font-weight-bold font-size-sm">
                                                        <timeago :datetime="formatDate(data.item.datetime)"></timeago>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-column mnw-150 text-right" v-else>
                                                    <span class="text-dark font-weight-bold font-size-sm">
                                                        <button type="button"
                                                            class="btn btn-ingomublue btn-sm text-white">New</button>
                                                    </span>
                                                </div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-model="currentMessagesPage" :total-rows="messageRows"
                                            :per-page="perPage" aria-controls="sessions-table"></b-pagination>
                                    </b-container>
                                </div>
                            </div>
                            <!--end: Card-->

                            <b-modal id="bv-modal-messages" size="lg" :title="messagesTitle" scrollable centered
                                ref="messages">
                                <!--begin::Messages-->
                                <b-container fluid>
                                    <div v-if="loading">
                                        <div class="d-flex justify-content-center mb-3 pt-100 pb-100">
                                            <b-spinner style="width: 3rem; height: 3rem;" label="Loading..."></b-spinner>
                                        </div>
                                    </div>
                                    <div id="messages" class="messages" v-else>
                                        <!--begin::Message In-->
                                        <div v-for="(item, i) in coachUserMessageItems" :key="i">
                                            <!--begin::Message Out-->
                                            <div class="d-flex flex-column mb-5 align-items-end" v-if="item.fromCoach">
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <span class="text-dark font-size-sm" v-if="item.datetime != ''">
                                                            <timeago :datetime="formatDate(item.datetime)"></timeago>
                                                        </span>
                                                        <span class="text-dark font-size-sm" v-else>
                                                            Just now
                                                        </span>
                                                        <a href="#"
                                                            class="text-dark text-hover-primary font-weight-bold font-size-h6 pl-5">You</a>
                                                    </div>
                                                    <div
                                                        class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center ml-3">
                                                        <img alt="Pic" :src="item.coach.coachProfileImage" />
                                                    </div>
                                                </div>
                                                <div
                                                    class="mt-2 rounded p-5 bg-ingomublue text-white font-weight-bold font-size-lg text-right max-w-400px html-text">
                                                    {{ item.text }}
                                                </div>
                                            </div>
                                            <!--end::Message Out-->

                                            <!--begin::Message Out-->
                                            <div class="d-flex flex-column mb-5 align-items-start" v-else>
                                                <div class="d-flex align-items-center">
                                                    <div
                                                        class="symbol symbol-45 symbol-xxl-45 mr-5 align-self-start align-self-xxl-center mr-3">
                                                        <img alt="Pic" :src="item.user.userProfileImage" />
                                                    </div>
                                                    <div>
                                                        <a href="#"
                                                            class="text-dark text-hover-primary font-weight-bold font-size-h6 pr-10">{{
                                                                item.user.userName }}</a>
                                                        <span class="text-dark font-size-sm" v-if="item.datetime != ''">
                                                            <timeago :datetime="formatDate(item.datetime)"></timeago>
                                                        </span>
                                                        <span class="text-dark font-size-sm" v-else>
                                                            just now
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="mt-2 rounded p-5 bg-ingomugreen text-white font-weight-bold font-size-lg text-left max-w-400px html-text">
                                                    {{ item.text }}
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Message In-->
                                    </div>
                                </b-container>
                                <!--end::Messages-->
                            </b-modal>


                            <!--begin::List Widget 10-->
                            <AdminWidget2 :coachlistid="coachid"></AdminWidget2>
                            <!--end: Card-->
                            <ListWidget11 :coachid="coachid"></ListWidget11>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListWidget11 from "@/view/content/widgets/list/Widget11.vue";
import AdminWidget1 from "@/view/content/widgets/admin/Widget1.vue";
import AdminWidget2 from "@/view/content/widgets/admin/Widget2.vue";
import AdminWidget3 from "@/view/content/widgets/admin/Widget3.vue";
import AdminWidget4 from "@/view/content/widgets/admin/Widget4.vue";
import AdminWidget5 from "@/view/content/widgets/admin/Widget5.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate"

export default {
    name: 'admincoachdetail',
    components: {
        ListWidget11,
        AdminWidget1,
        AdminWidget2,
        AdminWidget3,
        AdminWidget4,
        AdminWidget5
    },
    mixins: [formateDateMixin],
    data() {
        return {
            loading: false,
            coachMessageItems: [],
            coachUserMessageItems: [],
            perPage: 5,
            currentPage: 1,
            currentMessagesPage: 1,
            filter: null,
            headVariant: "light",
            sortBy: 'datetime',
            sortDesc: true,
            toMessageTitle: "",
            messagefields: [
                { key: "image", label: "Image" },
                { key: "title", label: "Name/Title", tdClass: 'w-100' },
                { key: "datetime", label: "Date", sortable: true, tdClass: 'va-m' },
            ],
        }
    },
    mounted() {
        this.$store.dispatch("getCoachMessagesListDocument", { coachid: this.coachid })
    },
    computed: {
        ...mapGetters(["coachMessagesAdmin", "coachMessagesForAdmin"]),
        coachid() {
            return this.$route.params.coachId
        },
        messageRows() {
            return this.coachMessageItems.length
        },
        messagesTitle() {
            return this.toMessageTitle
        }
    },
    methods: {
        mySortCompare(a, b, key) {
            if (key === 'datetime') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key]
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false
            }
        },
        loadMessage(dataItem) {
            this.toMessageTitle = dataItem.user.userName
            this.$store.dispatch("resetCoachMessagesForUser")
            this.$store.dispatch("getCoachMessageForUserDocument", { coachid: this.coachid, userid: dataItem.userid })
            this.loading = true
            this.$bvModal.show("bv-modal-messages");
        },
    },
    watch: {
        coachMessagesAdmin(newValue) {
            this.coachMessageItems = newValue
        },
        coachMessagesForAdmin(newValue) {
            this.coachUserMessageItems = newValue
            this.loading = false
        },
    }
}
</script>

<style scoped>
.h300 {
    height: 300px;
    overflow: scroll;
}

.center {
    margin: 0;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.fs-15 {
    font-size: 1.5rem !important;
}

.p-025 {
    padding: 0 25px;
}

.hw60100 {
    height: 60px !important;
    width: auto !important;
}

.hw60 {
    height: 60px;
    width: 60px;
}

.sessionimage {
    object-fit: contain;
    margin: 0 auto;
}

.symbol.symbol-light .symbol-label {
    background-color: white;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.fssmaller {
    font-size: 12px;
    font-weight: bold;
}

.font-size-lg {
    font-size: 1.08rem !important;
}

.text-dark {
    color: #3F4254 !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.mw-40 {
    min-width: 40px !important;
}

.mw-50 {
    min-width: 50px !important;
}

.mnw-150 {
    min-width: 150px !important;
}

.pointer {
    cursor: pointer;
}

.table-responsive-sm {
    min-height: 200px !important;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pr-10 {
    padding-right: 10px !important
}

.pl-5 {
    padding-left: 5px !important;
}

.html-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
}

.bg-ingomublue {
    background-color: #3b5998;
}

.bg-ingomugreen {
    background-color: #46c346;
}

/deep/ .table>tbody>tr>td.va-m {
    vertical-align: middle !important;
}
</style>