<template>
    <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark fs-15">
                    <h3 class="card-title font-weight-bolder text-dark fs-15">Upcoming Sessions</h3>
                </span>
                <span class="text-dark mt-3 font-weight-bold font-size-sm">All times are in EST. If the
                    coach is in test mode scheduled sessions will not show on the app.</span>
            </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body">
            <b-table id="latest-sessions-table" :items="adminCalendarDates" :fields="fields" show-empty
                thead-class="hidden_header" :per-page="perPage" :current-page="currentPage"
                class="table table-borderless table-vertical-center h-100 w-100 obcover br-42 align-self-center">
                <template #cell(dates)="data" class="pl-0 py-4">
                    <div class="d-flex align-items-center py-2">
                        <!--begin::Title-->
                        <span class="
                                            text-dark
                                            font-weight-bold
                                            text-hover-primary
                                            font-size-lg
                                            mb-1
                                        ">
                            {{ timestampToDate(data.item.livestream_time) }}
                        </span>
                        <!--end::Title-->
                    </div>
                </template>
                <template #cell(status)="data" class="pl-0 py-4">
                    <!--begin::Label-->
                    <div class="d-flex align-items-center py-2">
                        <button @click="changeScheduledStatus(true, data)" v-if="data.item.isCanceled == false"
                            class="label label-lg label-inline label-light-ingomugreen mr-2" :disabled="scheduledClicked.includes(data.item.id)">Scheduled</button>
                        <button @click="changeScheduledStatus(false, data)" v-else
                            class="label label-lg label-inline label-light-danger mr-2" :disabled="scheduledClicked.includes(data.item.id)">Cancelled</button>
                        <button @click="changeOnlineStatus(true, data)" v-if="data.item.online == false"
                            class="label label-lg label-inline label-light-danger mr-2" :disabled="onlineClicked.includes(data.item.id)">Offline</button>
                        <button @click="changeOnlineStatus(false, data)" v-else
                            class="label label-lg label-inline label-light-ingomugreen mr-2" :disabled="onlineClicked.includes(data.item.id)">Online</button>
                    </div>
                    <!--end::Label-->
                </template>
            </b-table>
            <div class="mt-10">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
                </b-pagination>
            </div>
        </div>
        <!--end: Card Body-->
        <!-- begin: Card Footer-->
        <div class="card-footer d-flex">
            <button @click="runSchedule(0)" class="btn btn-light-primary font-weight-bold mr-2" :disabled="thisMonth">Run This
                Month</button>
            <button @click="runSchedule(1)" class="btn btn-light-primary font-weight-bold mr-2" :disabled="nextMonth">Run Next
                Month</button>
            <button @click="runSchedule(2)" class="btn btn-light-primary font-weight-bold mr-2" :disabled="twoMonths">Run Two
                Months From Now</button>
        </div>
        <!-- end: Card Footer-->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "admin-widget-2",
    props: {
        coachlistid: String,
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "dates", label: "" },
                { key: "status", label: "" },
            ],
            scheduledClicked: [],
            onlineClicked: [],
            thisMonth: false,
            nextMonth: false,
            twoMonths: false
        }
    },
    mounted() {
        this.$store.dispatch("getAdminCoachCalSchedule", {
            coachListId: this.coachlistid,
        });
    },
    methods: {
        timestampToDate(ts) {
            let newdtt = new Date(ts.seconds * 1000).toUTCString().toString();
            return `${newdtt.substring(0, newdtt.length - 7)} EST`;
        },
        changeScheduledStatus(status, data) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.scheduledClicked.push(data.item.id)
                    // data.item.isCanceled = status
                    this.$store.dispatch("updatecoachcalendarschedulestatus", {
                        status: status,
                        id: data.item.id
                    });
                    Swal.fire("Processing!", "The coaches scheduled status will update shortly.", "success");
                }
            });
        },
        changeOnlineStatus(status, data) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.onlineClicked.push(data.item.id)
                    // data.item.online = status
                    this.$store.dispatch("updateCoachCalendarOnlineStatus", {
                        status: status,
                        id: data.item.id
                    });
                    Swal.fire("Processing!", "The coaches online status will update shortly.", "success");
                }
            });
        },
        runSchedule(month) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    switch (month) {
                        case 0:
                            this.thisMonth = true
                            break;
                        case 1:
                            this.nextMonth = true
                            break;
                        case 2:
                            this.twoMonths = true
                            break;
                    }
                    this.$store.dispatch("updateCoachCalendarSchedules", {
                        coach_list_id: this.coachlistid,
                        month: month
                    });
                    Swal.fire("Processing!", "The coaches calendar schedule will be updated shortly.", "success");
                }
            });
        },
    },
    computed: {
        ...mapGetters(["adminCalendarDates"]),
        coachid() {
            return this.coachlistid
        },
        rows() {
            return this.adminCalendarDates.length;
        },
    },


}
</script>
