<template>
    <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
            <h3 class="card-title font-weight-bolder text-dark fs-15">Personal Info</h3>
        </div>
        <!--begin::Form-->
        <form class="form" novalidate="novalidate" id="kt_save_form">
            <div class="card-body">
                <div class="form-group">
                    <label>First Name:</label>
                    <input type="text" name="firstname" ref="firstname" class="form-control form-control-solid"
                        placeholder="Enter first name" v-model="form.firstname" />
                    <span class="form-text text-dark">Please enter the coaches first name</span>
                </div>
                <div class="form-group">
                    <label>Last Name:</label>
                    <input type="text" name="lastname" ref="lastname" class="form-control form-control-solid"
                        placeholder="Enter last name" v-model="form.lastname" />
                    <span class="form-text text-dark">Please enter the coaches last name</span>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <div class="image-input image-input-outline" id="kt_profilephoto">
                            <label class="mb-5">Profile Image:</label>
                            <div class="image-input-wrapper"
                                :style="{ backgroundImage: `url(${updateProfilephoto})`, width: `200px`, height: `200px` }">
                            </div>
                            <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                data-original-title="Change profile photo">
                                <i class="fa fa-pen icon-sm text-dark"></i>
                                <input type="file" name="profilephoto" accept=".png, .jpg, .jpeg"
                                    @change="onProfileFileChange($event)" />
                                <input type="hidden" name="profile_profilephoto_remove" />
                            </label>
                            <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                title="Cancel profile photo">
                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                            </span>
                            <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip"
                                title="Remove profile photo" @click="current_updateprofilephoto = null; form.profilephoto = null;
                                ">
                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                            </span>
                        </div>
                        <span class="form-text text-dark mt-5">Allowed file types: png, jpg,
                            jpeg.</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-12">
                        <div class="image-input image-input-outline" id="kt_coachphoto">
                            <label class="mb-5">Coach Image:</label>
                            <div class="image-input-wrapper"
                                :style="{ backgroundImage: `url(${updateCoachphoto})`, width: `371px`, height: `214px` }">
                            </div>
                            <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                data-original-title="Change coach photo">
                                <i class="fa fa-pen icon-sm text-dark"></i>
                                <input type="file" name="coachphoto" accept=".png, .jpg, .jpeg"
                                    @change="onCoachFileChange($event)" />
                                <input type="hidden" name="profile_coachphoto_remove" />
                            </label>
                            <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                title="Cancel coach photo">
                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                            </span>
                            <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip" title="Remove coach photo"
                                @click="current_updatecoachphoto = null; form.coachphoto = null;
                                ">
                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                            </span>
                        </div>
                        <span class="form-text text-dark mt-5">Allowed file types: png, jpg,
                            jpeg.</span>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button ref="kt_save_changes1" class="btn btn-light-primary mr-2" :disabled="isDisabled">Update</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: 'admin-widget-1',
    props: {
        coachlistid: String,
    },
    data() {
        return {
            form: {
                firstname: "",
                lastname: "",
                profilephoto: null,
                coachphoto: null,
                coachlistid: this.coachlistid,
                userid: ""
            },
            default_updateprofilephoto: "/media/users/200.png",
            current_updateprofilephoto: null,
            default_updatecoachphoto: "/media/users/371_214.png",
            current_updatecoachphoto: null,
            isDisabled: false
        }
    },
    mounted() {
        this.$store.dispatch("getAdminProgramCoachDetail", {
            coachId: this.coachlistid,
        });

        const form = KTUtil.getById("kt_save_form");
        this.fv1 = formValidation(form, {
            fields: {
                firstname: {
                    validators: {
                        notEmpty: {
                            message: "First name is required",
                        },
                    },
                },
                lastname: {
                    validators: {
                        notEmpty: {
                            message: "Last name is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv1.on("core.form.valid", () => {
            this.isDisabled = true
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes1"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("updateCoachPersonalInfo", this.form)
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: "The account has been updated.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv1.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    methods: {
        onProfileFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_updateprofilephoto = event.target.result;
                    this.form.profilephoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onCoachFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_updatecoachphoto = event.target.result;
                    this.form.coachphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        }

    },
    computed: {
        ...mapGetters(["adminCoachDetails"]),
        updateProfilephoto() {
            return this.current_updateprofilephoto == null || this.current_updateprofilephoto == "undefined"
                ? this.default_updateprofilephoto
                : this.current_updateprofilephoto;
        },
        updateCoachphoto() {
            return this.current_updatecoachphoto == null || this.current_updatecoachphoto == "undefined"
                ? this.default_updatecoachphoto
                : this.current_updatecoachphoto;
        }
    },
    watch: {
        adminCoachDetails(newValue) {
            this.form.firstname = newValue.coach.first
            this.form.lastname = newValue.coach.last
            this.form.userid = newValue.coach.user_id
            if (newValue.coach.image != "") {
                this.default_updateprofilephoto = newValue.coach.image
            }
            if (newValue.coach.largeImage != "") {
                this.default_updatecoachphoto = newValue.coach.largeImage
            }
        }
    }

}
</script>

<style scoped>
.mt-35 {
    margin-top: 35px !important;
}
</style>
