<template>
    <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
            <h3 class="card-title font-weight-bolder text-dark fs-15">Coaching Community</h3>
        </div>
        <!--begin::Form-->
        <form class="form" novalidate="novalidate" id="kt_save_form1">
            <div class="card-body">
                <div class="form-group">
                    <label for="exampleTextarea">Coach Bio <span class="text-danger">*</span></label>
                    <textarea class="form-control" name="bio" ref="bio" id="bio" rows="3" v-model="form.bio"></textarea>
                    <span class="form-text text-dark">Use \n for new lines or breaks</span>
                </div>
                <div class="form-group">
                    <label for="exampleTextarea">Coaching Community Description <span
                            class="text-danger">*</span></label>
                    <textarea class="form-control" name="description" ref="description" id="description" rows="3"
                        v-model="form.description"></textarea>
                    <span class="form-text text-dark">Use \n for new lines or breaks</span>
                </div>
                <div class="form-group">
                    <label>Coaching Community Title: <span class="text-danger">*</span></label>
                    <input type="text" name="title" ref="title" class="form-control" placeholder="Enter the title"
                        v-model="form.title" />
                    <span class="form-text text-dark">Please enter the title</span>
                </div>
                <div class="form-group">
                    <label>Coaching Community Category: <span class="text-danger">*</span></label>
                    <b-form-select v-model="form.category" :options="categoryOptions" class="form-control"
                        name="category" ref="category"></b-form-select>
                </div>
                <div class="form-group row">
                    <label>Coaching Community Keywords:</label>
                    <b-form-tags ref="keywords" name="keywords" input-id="tags-basic" v-model="form.keywords"
                        class="form-control"></b-form-tags>
                    <span class="form-text text-dark">Please enter the keywords and press
                        enter</span>
                </div>
                <div class="form-group row">
                    <label>Preview Video: <span class="text-danger">*</span></label>
                    <input type="text" name="video" ref="video" class="form-control" placeholder="Enter the video url"
                        v-model="form.video" />
                    <span class="form-text text-dark">Must be an m3u8 file and uploaded to
                        Amazon S3 ingomucdn bucket.</span>
                </div>
            </div>
            <div class="card-footer">
                <button ref="kt_save_changes1" class="btn btn-light-primary mr-2" :disabled="isDisabled">Update</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: "admin-widget-4",
    props: {
        coachlistid: String,
    },
    data() {
        return {
            form: {
                bio: "",
                description: "",
                title: "",
                category: "",
                video: "https://ingomucdn.s3.amazonaws.com/video/videoplaceholder.m3u8",
                keywords: [],
                coachlistid: this.coachlistid,
                userid: ""
            },
            categoryOptions: [],
            isDisabled: false
        }
    },
    mounted() {
        this.$store.dispatch("getCoachCategories");
        this.$store.dispatch("getAdminProgramCoachDetail", {
            coachId: this.coachlistid,
        });

        const form = KTUtil.getById("kt_save_form1");
        this.fv1 = formValidation(form, {
            fields: {
                bio: {
                    validators: {
                        notEmpty: {
                            message: "Bio is required",
                        },
                    },
                },
                description: {
                    validators: {
                        notEmpty: {
                            message: "Description is required",
                        },
                    },
                },
                title: {
                    validators: {
                        notEmpty: {
                            message: "Title is required",
                        },
                    },
                },
                category: {
                    validators: {
                        notEmpty: {
                            message: "Category is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv1.on("core.form.valid", () => {
            this.isDisabled = true
            
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes1"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("updateCoachCommunity", this.form)
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: "The account has been updated.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv1.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["adminCoachDetails", "categories"]),
    },
    watch: {
        adminCoachDetails(newValue) {
            this.form.bio = newValue.coach.bio
            this.form.description = newValue.description
            this.form.title = newValue.title
            this.form.category = newValue.category[0]
            this.form.video = newValue.video
            this.form.keywords = newValue.keywords
            this.form.userid = newValue.coach.user_id
        },
         categories(newValue) {
            let newOptions = []
            newValue.forEach(element => {
                newOptions.push({ value: element.category, text: element.category })
            })
            this.categoryOptions = newOptions;
        }
    }


}
</script>
