<template>
    <div class="card card-custom gutter-b">
        <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex mb-9">
                <!--begin: Pic-->
                <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                    <div class="symbol symbol-50 symbol-lg-120">
                        <img :src="profilephoto" alt="image" />
                    </div>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                    <!--begin::Title-->
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex mr-3">
                            <div class="text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                                {{ currentCoachName }}</div>
                        </div>
                        <div class="my-lg-0 my-3">
                            <button @click="changeCoachOnlineStatus(false)" v-if="onlineStatus == true"
                                class="label label-xl label-inline label-light-success mr-2" :disabled="onlineDisabled">
                                Online
                            </button>
                            <button @click="changeCoachOnlineStatus(true)" v-else
                                class="label label-xl label-inline label-light-danger mr-2" :disabled="onlineDisabled">
                                Offline
                            </button>
                            <button @click="changeCoachTestStatus(false)" v-if="testStatus == true"
                                class="label label-xl label-inline label-light-danger mr-2" :disabled="testDisabled">
                                Test
                            </button>
                            <button @click="changeCoachTestStatus(true)" v-else
                                class="label label-xl label-inline label-light-success mr-2" :disabled="testDisabled">
                                Live
                            </button>
                        </div>
                    </div>
                    <!--end::Title-->
                    <!--begin::Content-->
                    <div class="d-flex flex-wrap justify-content-between mt-1">
                        <div class="d-flex flex-column flex-grow-1 pr-8">
                            <div class="d-flex flex-wrap mb-4">
                                <div class="text-dark text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                                    v-if="currentCoachEmail != ''">
                                    <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{
                                            currentCoachEmail
                                    }}
                                </div>
                                <div class="text-dark text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                    <i class="flaticon2-box mr-2 font-size-lg"></i>{{
                                            currentCoachCategory
                                    }}
                                </div>
                                <div class="text-dark text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                    <i class="flaticon-customer mr-2 font-size-lg"></i>{{
                                            currentCoachCoachListId
                                    }}
                                </div>
                            </div>
                            <span class="font-weight-bold text-dark" v-if="currentCoachBio != ''">{{
                                    currentCoachBio
                            }}</span>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "admin-widget-3",
    props: {
        coachlistid: String,
    },
    data() {
        return {
            default_profilephoto: "/media/users/200.png",
            current_profilephoto: null,
            currentCoachName: "",
            currentCoachEmail: "",
            currentCoachCategory: "",
            currentCoachCoachListId: "",
            currentCoachBio: "",
            onlineStatus: true,
            testStatus: false,
            onlineDisabled: false,
            testDisabled: false
        }
    },
    mounted() {
        this.$store.dispatch("getAdminProgramCoachDetail", {
            coachId: this.coachlistid,
        });
    },
    methods: {
        changeCoachOnlineStatus(status) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.onlineDisabled = true
                    // this.onlineStatus = status
                    this.$store.dispatch("updateCoachOnlineStatus", {
                        status: status,
                        uid: this.coachlistid
                    });
                    Swal.fire("Processing!", "Your online status will update shortly.", "success");
                }
            });
        },
        changeCoachTestStatus(status) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.testDisabled = true
                    // this.textStatus = status
                    this.$store.dispatch("updateCoachTestStatus", {
                        status: status,
                        uid: this.coachlistid
                    });
                    Swal.fire("Processing!", "Your test status will update shortly.", "success");
                }
            });
        },
    },
    computed: {
        ...mapGetters(["adminCoachDetails", "programCoachDetail"]),
        coachid() {
            return this.coachlistid
        },
        profilephoto() {
            return this.current_profilephoto == null || this.current_profilephoto == "undefined"
                ? this.default_profilephoto
                : this.current_profilephoto;
        },
    },
    watch: {
        adminCoachDetails(newValue) {
            if (newValue.coach.image && newValue.coach.image != "") {
                this.current_profilephoto = newValue.coach.image
            }
            if (newValue.coach.first != "" && newValue.coach.last != "") {
                this.currentCoachName = newValue.coach.first + " " + newValue.coach.last
            }
            if (newValue.coach.category != "") {
                this.currentCoachCategory = newValue.category[0]
            }
            if (newValue.coach.bio != "") {
                this.currentCoachBio = newValue.coach.bio
            }
            this.onlineStatus = newValue.online
            this.testStatus = newValue.isTest
            this.currentCoachCoachListId = newValue.id
        },
        programCoachDetail(newValue) {
            if (newValue.length > 0 && newValue[0].email != "") {
                this.currentCoachEmail = newValue[0].email
            }
        }
    }

}
</script>
