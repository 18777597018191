<template>
    <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
            <h3 class="card-title font-weight-bolder text-dark fs-15">Schedule</h3>
        </div>
        <!--begin::Form-->
        <form class="form" novalidate="novalidate" id="kt_save_form2">
            <div class="card-body">
                <div class="form-group">
                    <label>Livestream Time: <span class="text-danger">*</span></label>
                    <input type="text" name="livestreamtimes" ref="livestreamtimes" class="form-control"
                        placeholder="Enter the livestream times" v-model="form.livestreamtimes" />
                    <span class="form-text text-dark">This is a string representation (i.e, 4th
                        Tuesday at 3:00pm Eastern Time) </span>
                </div>
                <div class="form-group">
                    <label>Day of the Week: <span class="text-danger">*</span></label>
                    <select class="form-control" name="livestreamday" ref="livestreamday" id="livestreamday"
                        v-model="form.livestreamday">
                        <option value="0">Sunday</option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Hour: <span class="text-danger">*</span></label>
                    <select class="form-control" name="livestreamhour" ref="livestreamhour" id="livestreamhour"
                        v-model="form.livestreamhour">
                        <option value="1">1am</option>
                        <option value="2">2am</option>
                        <option value="3">3am</option>
                        <option value="4">4am</option>
                        <option value="5">5am</option>
                        <option value="6">6am</option>
                        <option value="7">7am</option>
                        <option value="8">8am</option>
                        <option value="9">9am</option>
                        <option value="10">10am</option>
                        <option value="11">11am</option>
                        <option value="12">12pm</option>
                        <option value="13">1pm</option>
                        <option value="14">2pm</option>
                        <option value="15">3pm</option>
                        <option value="16">4pm</option>
                        <option value="17">5pm</option>
                        <option value="18">6pm</option>
                        <option value="19">7pm</option>
                        <option value="20">8pm</option>
                        <option value="21">9pm</option>
                        <option value="22">10pm</option>
                        <option value="23">11pm</option>
                        <option value="24">12am</option>
                    </select>
                    <span class="form-text text-dark">All times are in Eastern Standard
                        Time.</span>
                </div>
                <div class="form-group">
                    <label>Minute: <span class="text-danger">*</span></label>
                    <select class="form-control" name="livestreamminute" ref="livestreamminute" id="livestreamminute"
                        v-model="form.livestreamminute">
                        <option value="0">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                    </select>
                </div>
                <div class="form-group row">
                    <label>Reoccuring: <span class="text-danger">*</span></label>
                    <b-form-select v-model="form.livestreamweek" :options="weekoptions" class="form-control" multiple
                        name="livestreamweek" ref="livestreamweek" :select-size="4"></b-form-select>
                    <span class="form-text text-dark">Which weeks in the month does this
                        reoccur. Hold down cmd key on mac or start/windows key on pc to select
                        multiple.</span>
                </div>
            </div>
            <div class="card-footer">
                <button ref="kt_save_changes1" class="btn btn-light-primary mr-2" :disabled="isDisabled">Update</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: "admin-widget-5",
    props: {
        coachlistid: String,
    },
    data() {
        return {
            form: {
                livestreamtimes: "",
                livestreamday: "",
                livestreamhour: "",
                livestreamminute: "",
                livestreamweek: [],
                coachlistid: this.coachlistid,
                userid: ""
            },
            categoryOptions: [],
            weekoptions: [
                { value: 1, text: '1st Week' },
                { value: 2, text: '2nd Week' },
                { value: 3, text: '3rd Week' },
                { value: 4, text: '4th Week' },
            ],
            isDisabled: false
        }
    },
    mounted() {
        this.$store.dispatch("getAdminProgramCoachDetail", {
            coachId: this.coachlistid,
        });

        const form = KTUtil.getById("kt_save_form2");
        this.fv1 = formValidation(form, {
            fields: {
                livestreamtimes: {
                    validators: {
                        notEmpty: {
                            message: "Livestream time is required",
                        },
                    },
                },
                livestreamday: {
                    validators: {
                        notEmpty: {
                            message: "Livestream day is required",
                        },
                    },
                },
                livestreamhour: {
                    validators: {
                        notEmpty: {
                            message: "Livestream hour is required",
                        },
                    },
                },
                livestreamminute: {
                    validators: {
                        notEmpty: {
                            message: "Livestream minute is required",
                        },
                    },
                },
                livestreamweek: {
                    validators: {
                        notEmpty: {
                            message: "Livestream week is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv1.on("core.form.valid", () => {
            this.isDisabled = true
            
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes1"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("updateCoachSchedule", this.form)
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: "The account has been updated.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv1.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["adminCoachDetails"]),
    },
    watch: {
        adminCoachDetails(newValue) {
            this.form.livestreamtimes = newValue.livestream_times
            this.form.livestreamday = newValue.livestreamday
            this.form.livestreamhour = newValue.livestreamhour
            this.form.livestreamminute = newValue.livestreamminute
            this.form.livestreamweek = newValue.livestreamweek
            this.form.userid = newValue.coach.user_id
        }
    }


}
</script>
